import { Link } from 'react-scroll';
import React from 'react';

export default function Header() {
  // const handleSetActive = to => {
  //   console.log(to);
  // };
  const [active, setActive] = React.useState(false);

  const handleMenuToggle = () => {
    setActive(!active);
  };

  const links = [
    { label: 'Home', target: 'home' },
    { label: 'About', target: 'about' },
    // { label: 'Services', target: 'service' },
    { label: 'Experiences', target: 'experience' },
    // { label: 'Works', target: 'work' },
    { label: 'Contact', target: 'contact' },
  ];

  return (
    <header className="desktop-header-3 fixed-top">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <Link to={links[0].target} spy={true} smooth={true} offset={-30} duration={500} className="navbar-brand" href={'#' + links[0].target}>
            <img src="/images/logo_bright_text.png" alt="Jonathan Christianto" style={{ height: '33px' }} />
          </Link>

          <button
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navbarNavDropdown"
            data-toggle="collapse"
            type="button"
            onClick={handleMenuToggle}
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className={'collapse navbar-collapse' + (active ? ' show' : '')} id="navbarNavDropdown">
            <ul className="navbar-nav ml-auto scrollspy">
              {links.map((link, index) => (
                <li className="nav-item" key={index}>
                  <Link
                    activeClass="active"
                    to={link.target}
                    spy={true}
                    smooth={true}
                    offset={-30}
                    duration={500}
                    className="nav-link"
                    href={'#' + link.target}
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}
