import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import ScrollAnimation from 'react-animate-on-scroll';
import UtilDate from '../utils/date';

export default function SectionExperience({ experiences }) {
  const sorted = experiences.map(experience => {
    return {
      ...experience,
      values: experience.values.sort((a, b) => new Date(a.year_start) - new Date(b.year_start)), // Sorted descending
    };
  });
  sorted.reverse();

  const avg = parseInt(Math.floor(sorted.length / 2), 10);
  const blocks = [sorted.slice(0, avg), sorted.slice(avg)];

  return (
    <section id="experience">
      <div className="container">
        <ScrollAnimation animateIn="fadeInUp" delay={300} animateOnce={true}>
          <h2 className="section-title">Experiences</h2>
        </ScrollAnimation>

        <div className="spacer" style={{ height: '60px' }} />

        <div className="row">
          {blocks.map((block, groupIndex) => (
            <div className="col-md-6" key={'block-' + groupIndex}>
              {groupIndex >= 1 ? <div className="spacer d-md-none d-lg-none" style={{ height: '30px' }} /> : <></>}
              <div className="timeline bg-dark rounded shadow-light padding-30 overflow-hidden">
                {block.map((item, itemIndex) => {
                  const experience = item.values;
                  const lastIndex = experience.length;
                  const positions = experience.map(exp => exp.title).reverse();
                  const dates = {
                    start: experience[0].year_start,
                    end: lastIndex === 0 ? experience[0].year_end : experience[lastIndex - 1].year_end,
                  };

                  return (
                    <div
                      className={'timeline-container ' + (item.type === 'education' ? 'edu' : 'exp')}
                      style={{ display: item.highlight ? 'block' : 'block' }}
                      key={'ex[experience-' + itemIndex}
                    >
                      <ScrollAnimation animateIn="fadeInUp" delay={300} animateOnce={true}>
                        <div className="content">
                          <span className="time">{UtilDate.dateRange(dates.start, dates.end, 'Unspecified', 'Present', 'YYYY', null, null)}</span>
                          <h3 className="title">{positions.join(', ')}</h3>
                          <p>{experience.map(exp => exp.description)}</p>
                          <div className="mt-3">
                            <div className="small" style={{ lineHeight: '0.7em', color: '#FF4C60' }}>
                              {item.company.name}
                            </div>
                            <div>
                              <a href={item.company.url} target="_blank" rel="noreferrer noopener" className="small text-lowercase text-secondary">
                                {item.company.url}
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" className="ml-1" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </ScrollAnimation>
                    </div>
                  );
                })}
                <span className="line" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
