import Head from 'next/head';
import React from 'react';
import { Element, animateScroll as scroll } from 'react-scroll';
import { Waypoint } from 'react-waypoint';
import Header from '../components/Header';
import SectionAbout from '../components/SectionAbout';
import SectionContact from '../components/SectionContact';
import SectionExperience from '../components/SectionExperience';
import SectionHome from '../components/SectionHome';
import SectionRecommendation from '../components/SectionRecommendation';

export default function Home() {
  // const roles = ['a Frontend Engineer', 'a Backend Engineer', 'an Engineering Manager', 'a Web Designer'];
  const roles = ['Senior Frontend Engineer', 'Engineering Manager', 'Web Designer'];

  const icons = [
    // { platform: 'instagram', url: 'https://instagram.com/jnthnchrstnt' },
    { platform: 'twitter', url: 'https://twitter.com/jo_christianto' },
    { platform: 'linkedin', url: 'https://www.linkedin.com/in/jochristianto' },
  ];

  const skills = [
    { id: 'web_development', label: 'Web Development', color: '#6C6CE5', percentage: 90 },
    { id: 'design', label: 'Web Design', color: '#FF4C60', percentage: 80 },
    { id: 'system_design', label: 'System Design', color: '#FFD15C', percentage: 70 },
  ];

  const experiences = [
    // UPH
    {
      highlight: true,
      type: 'education',
      company: { name: 'Pelita Harapan University', url: 'https://www.uph.edu' },
      values: [{ year_start: '2009-07-01', year_end: '2013-06-01', title: 'Bachelor of Information Systems' }],
    },
    // UPH
    {
      highlight: false,
      type: 'work',
      company: { name: 'Pelita Harapan University', url: 'https://www.uph.edu' },
      values: [
        {
          year_start: '2011-07-01',
          year_end: '2011-12-01',
          title: 'Lecture Assistant',
          description:
            'Assisting lecturers to teach in laboratory classes for undergraduate students, for Internet Application and Visual Programming subject.',
        },
      ],
    },

    // Vi8e
    {
      highlight: false,
      type: 'work',
      company: { name: 'Vi8e Interactive Pte Ltd', url: 'https://www.vi8e.com' },
      values: [{ year_start: '2011-12-01', year_end: '2015-01-01', title: 'Web Developer' }],
    },
    {
      highlight: false,
      type: 'work',
      company: { name: 'Vi8e Interactive Pte Ltd', url: 'https://www.vi8e.com' },
      values: [
        {
          year_start: '2015-01-01',
          year_end: '2016-10-01',
          title: 'Lead Web Developer',
          description:
            'Started as a WordPress developer who was then assigned to become a Web Developer that handles almost the entire project development cycle, and assist project managers to explain technical specifications of user requests to developers.',
        },
      ],
    },

    // Bukalapak
    {
      highlight: true,
      type: 'work',
      company: { name: 'PT Bukalapak.com', url: 'https://www.bukalapak.com' },
      values: [
        {
          year_start: '2016-11-01',
          year_end: '2019-01-01',
          title: 'Front-End Engineer',
          description:
            "Part of the Growth team that help to increase the rate of customer acquisition, retention, and revenue by optimizing products and inventing new marketing channels. As well as be the main contributor of 2019's Bukalapak's Career site development.",
        },
      ],
    },
    {
      highlight: true,
      type: 'work',
      company: { name: 'PT Bukalapak.com', url: 'https://www.bukalapak.com' },
      values: [
        {
          year_start: '2019-02-01',
          year_end: '2019-09-01',
          title: 'Senior Front-End Engineer',
          description:
            'Help to solve tribe-wide issues for both on development or even production stage. Also, work with two teams under O2O tribe that handles Mitra Bukalapak make their life easier by providing access to virtual products, product management, and dozens of other features.',
        },
      ],
    },

    // OVO
    {
      highlight: true,
      type: 'work',
      company: { name: 'PT Visionet Internasional (OVO)', url: 'https://ovo.id' },
      values: [
        {
          year_start: '2019-09-01',
          year_end: '2020-02-01',
          title: 'Senior Front-End Engineer',
          description:
            'Work with Loyalty team members to setup authentication page to be used by third parties, such as Grab, and Tokopedia. Provide better support for our users in a simple way, called Help Center. Setup a new dashboard from scratch using Nuxt, Vue, and Webpack. As well as to prepare some documentations for legacy services, and setup some coding guideline and standards.',
        },
      ],
    },

    // Wowrack
    {
      highlight: false,
      type: 'work',
      company: { name: 'PT Wowrack Indonesia', url: 'https://www.wowrack.co.id' },
      values: [
        {
          year_start: '2020-02-01',
          year_end: '2020-10-01',
          title: 'Senior Front-End Engineer',
          description:
            'Built web-based application from scratch with microservice architecture in-mind, using Nuxt.js, Vue.js, Docker, and Nginx. Also, help back end to setup some API services, REST API standard, and its API documentation.',
        },
      ],
    },

    // Mekari
    {
      highlight: true,
      type: 'work',
      company: { name: 'PT Mid Solusi Nusantara (Mekari)', url: 'https://www.mekari.com' },
      values: [
        {
          year_start: '2020-10-01',
          year_end: '2021-07-01',
          title: 'Senior Front-End Engineer',
          description:
            'Work closely with other team members for feature development and improvements for multiple products across Mekari, help the frontend department to setup some coding and development standards, and help the talent acquisition team to interview some software engineer candidates.',
        },
      ],
    },
    {
      highlight: true,
      type: 'work',
      company: { name: 'PT Mid Solusi Nusantara (Mekari)', url: 'https://www.mekari.com' },
      values: [
        {
          year_start: '2021-07-01',
          year_end: '2022-05-01',
          title: 'Engineering Manager',
          description:
            'Help backend and frontend team members by making sure everything they need is available before they ask for it, including technical RFCs, and requests for resources. And actively participate in scrum events with POs, TPMs, and team members, both internally within the team or across teams.',
        },
      ],
    },

    // TipTip
    {
      highlight: true,
      type: 'work',
      company: { name: 'PT Tiptip Network Indonesia', url: 'https://www.tiptip.co' },
      values: [
        {
          year_start: '2022-05-01',
          year_end: '2022-08-31',
          title: 'Front-End Engineer',
          description:
            'Work together with Designers, and Product Owners to develop features for the web platform, as well as to help with the technical debts.',
        },
      ],
    },

    // GovTech
    {
      highlight: true,
      type: 'work',
      company: { name: 'GovTech Edu @ Telkom Indonesia', url: 'https://www.govtechedu.id' },
      values: [
        {
          year_start: '2022-09-01',
          year_end: null,
          title: 'Front-End Engineer',
          description: '',
        },
      ],
    },
  ];

  const recommendations = [
    {
      name: 'Rizal Asrul Pambudi',
      url: 'https://www.linkedin.com/in/rizalasrul',
      avatar_url:
        'https://media-exp1.licdn.com/dms/image/C5603AQGjo27Gy5DcVg/profile-displayphoto-shrink_100_100/0/1644331593016?e=1656547200&v=beta&t=WkLWvSrLaTr-F2H56bY2ZUa_4ItCJbm8XlU5lD1Oc0M',
      role: 'Product Engineer at PT Bukalapak.com',
      date: '2019-09-12',
      message:
        "Jonathan Christianto proved to be vital to the team, and contributed significantly to the development of the company. He's got a very strong work ethic and unparalleled analytical and problem solving skills. Jo provided outstanding results for Bukalapak. Is always capable of adapting to new working environments. Very positive attitude towards work. Dedicated and goal oriented strategist that cannot be overestimated. Jo makes the impossible possible. Experiencing his fast and sharp mind at the workplace is most impressive.",
    },
    {
      name: 'Iqbal Satria',
      url: 'https://www.linkedin.com/in/iqbalmabbit',
      avatar_url:
        'https://media-exp1.licdn.com/dms/image/C5603AQG9br7VaiikdA/profile-displayphoto-shrink_100_100/0/1517075455213?e=1656547200&v=beta&t=0ydj2H1tO7HNYfYbaqlSbR6U3r9vznIMynD8JhQMxjU',
      role: 'O2O UX Research Lead at PT Bukalapak.com',
      date: '2019-09-13',
      message:
        "Jo is one of the best engineer I've ever work with. Even though he work remotely, there is no hurdle in working with him. He's very sharp and critical, he often gives us a constructive input so that we can create a better output. ",
    },
    {
      name: 'Rashemi Rafsanjany',
      url: 'https://www.linkedin.com/in/akasection',
      avatar_url:
        'https://media-exp1.licdn.com/dms/image/C4E03AQHNx6z75H9A0Q/profile-displayphoto-shrink_100_100/0/1522723590660?e=1656547200&v=beta&t=d0mwkftLrjx_3fRIM5Q53nbfRQhxLHGg22FpgZs4H1I',
      role: 'Front End Engineer at PT Bukalapak.com',
      date: '2019-09-13',
      message:
        'In O2O "Mitra" Bukalapak, Jo and me worked together to achieve best mitra web app performance possible. His quickness to respond and offer initiatives helped the team automate most of things that USED TO BE done manually and disorganized. One year being with him in O2O team really gives a very good experience about teamwork and being FE expert. In addition, Jo is pretty critical and always ready to challenge and criticize any task/work to suit team needs and actually pragmatic enough to deliver values in iterative.',
    },
    {
      name: 'Ibrahim Arief',
      url: 'https://www.linkedin.com/in/ibrahimarief',
      avatar_url:
        'https://media-exp1.licdn.com/dms/image/C5103AQHQwcih7y316Q/profile-displayphoto-shrink_100_100/0/1585234083265?e=1656547200&v=beta&t=wJpeIJBNCyqyXi3oBjdTKz_HyPc3zq08oW3dsRqtQ1M',
      role: 'VP of Engineering at PT Bukalapak.com',
      date: '2019-09-17',
      message:
        'Jo is a highly self-motivated individual that never hesitate to go the extra mile in building great web products for our customers. He is a great and seamless collaborator even though he lives 700 km away from the rest of the team. He frequently flies to Jakarta on his own volition to sync better with his team, and he is an all around dependable teammate to have.',
    },
    {
      name: 'Mgs M Rizqi Fadhlurrahman',
      url: 'https://www.linkedin.com/in/mgsrizqi',
      avatar_url:
        'https://media-exp1.licdn.com/dms/image/C5603AQFqCbdNE0YNLA/profile-displayphoto-shrink_100_100/0/1517560044714?e=1656547200&v=beta&t=l-01YoSgZA6RlGEIv4j7T4EZw2B4U4Qr8n0-qtp9vzg',
      role: 'Front End Engineer at PT Bukalapak.com',
      date: '2019-09-19',
      message:
        'Mas Jo is one of the best Front End Engineer in my tribe in Bukalapak. He specialized in things related to production and deployment for our Front End team. Even though he was working remotely, the communication went smoothly.',
    },
    {
      name: 'Rahmi Suci Ramadhani',
      url: 'https://www.linkedin.com/in/rahmisr',
      avatar_url:
        'https://media-exp1.licdn.com/dms/image/C5103AQFzfusGHFJXgg/profile-displayphoto-shrink_100_100/0/1567057378600?e=1656547200&v=beta&t=CXcQcbRIwdMJc2UGLTTkHoNXrmrc77zKbA3UqE9CxQ8',
      role: 'Associate Product Manager - O2O at PT Bukalapak.com',
      date: '2019-10-12',
      message:
        'Mas Jo is a very talented engineer! He has always delivered his work on time (even faster). More importantly, he never hesitate to share his time and knowledge to collaborate with junior engineers and help them grow. He provided constructive feedback and solution when we had technical blockers. It was an honor to work together with him :)',
    },
    {
      name: 'Rahman Kurnia',
      url: 'https://www.linkedin.com/in/rahman-kurnia-609079a8',
      avatar_url:
        'https://media-exp1.licdn.com/dms/image/C4E03AQGYmuF1SmMNgw/profile-displayphoto-shrink_100_100/0/1517486990046?e=1656547200&v=beta&t=2J5bJZz5wHYR5V3ZJzDxWRvAtn3PYKE4jmE847_jb-s',
      role: 'Software Engineer at OVO (PT Visionet Internasional)',
      date: '2020-03-01',
      message:
        "Jo is one of the best frontend engineer i've ever met. He worked based on the principles. He shared alot of frontend's best practices to his team mates and project mates. I have no doubt at him since first time i see him joining our team at OVO.",
    },
    {
      name: 'Luthfan Rasyad Maulana',
      url: 'https://www.linkedin.com/in/luthfan-rasyad-maulana-92a278117',
      avatar_url:
        'https://media-exp1.licdn.com/dms/image/C5603AQFnslcBJDn20Q/profile-displayphoto-shrink_100_100/0/1566294307294?e=1656547200&v=beta&t=7vmswGJ1Hi7mH3ghdHzKm_4lASOI9kGU5WkpcFW77Eo',
      role: 'Frontend Engineer at OVO (PT Visionet Internasional)',
      date: '2020-09-23',
      message:
        "During our time working together, Jonathan was very driven and passionate about the work that he did. He was meticulous and detail oriented, which rubbed off on me. Not only does he excel technically, he also has what it takes to become a lead or manager someday. I'm grateful to have met Jonathan such early on in my career.",
    },
    {
      name: 'Rahmaniansyah Dwi Putri',
      url: 'https://www.linkedin.com/in/rahmaniansyahdwiputri',
      avatar_url:
        'https://media-exp1.licdn.com/dms/image/C5603AQHsErKZnJ4J-A/profile-displayphoto-shrink_100_100/0/1517239987824?e=1656547200&v=beta&t=NtRX9ni1wr712w6YLQW0MtGxTp_jDNCWNUkJQmn_yXI',
      role: 'Software Engineer at Mekari (PT Mid Solusi Nusantara)',
      date: '2022-03-31',
      message:
        "Mas Jo is a good friend, co-worker and leader in the team, as a leader he guides the team to complete any tasks well, he provides direction but does not limit the team's creativity in carrying out tasks. As a co-worker and friend he is always helpful and encouraging, not only about work but he is very good at giving life advice, it's really nice to work together with Mas Jo",
    },
    {
      name: 'Daniel Listyo Emanuel',
      url: 'https://www.linkedin.com/in/daniel-listyo-emanuel',
      avatar_url:
        'https://media-exp1.licdn.com/dms/image/C5603AQH5xroSm0GygQ/profile-displayphoto-shrink_100_100/0/1565917314357?e=1656547200&v=beta&t=nt9SJzibXJBIs_GHjBQlkkSHIoPBEtTrBLgzwbQJ2jY',
      role: 'Frontend Engineer at Mekari (PT Mid Solusi Nusantara)',
      date: '2022-04-01',
      message:
        'He is Excellent Frontend Engineer. Humble and Calm. good Teamwork. Responsible about the Task. Have Very good Communication. Right now he is managing people as Engineering Manager. I have ever been managed with him. He was good at managing people.',
    },
    {
      name: 'Azzam Jihad Ulhaq',
      url: 'https://www.linkedin.com/in/azzamjiul',
      avatar_url:
        'https://media-exp1.licdn.com/dms/image/C5603AQGz-nu_odihTQ/profile-displayphoto-shrink_100_100/0/1605633120656?e=1656547200&v=beta&t=CnbT4u5XBdKF9hlEhCeHEZ7keuJ38XFQibux3_pNCXY',
      role: 'Software Engineer at Mekari (PT Mid Solusi Nusantara)',
      date: '2022-04-04',
      message:
        "I had the opportunity to work with Jonathan during my tenure at Mekari. Jonathan drives and is passionate about the work he does. Apart from his excellent technical skills, he also had good leadership skills during his time as a leader in my team. He is very humble and cares about the team members. As a detail-oriented person, Jonathan pays attention to details that we often miss and saves the team from mistakes that shouldn't have happened. I'm grateful to have worked with Jonathan as my manager in my early career journey.",
    },
  ];

  const clients = [
    {
      name: 'Bukalapak',
      logo_url: '/images/logo/logo-bukalapak.png',
      logo_url_2: '/images/logo/logo-bukalapak@2x.png',
      url: 'https://www.bukalapak.com',
    },
    {
      name: 'Mekari',
      logo_url: '/images/logo/logo-talenta.png',
      logo_url_2: '/images/logo/logo-talenta.png',
      url: 'https://www.mekari.com',
    },
    {
      name: 'OVO',
      logo_url: '/images/logo/logo-ovo.png',
      logo_url_2: '/images/logo/logo-ovo@2x.png',
      url: 'https://www.ovo.id',
    },
  ];

  const [active, setActive] = React.useState(false);

  const handleWaypointEnter = () => {
    setTimeout(() => {
      setActive(true);
    }, 500);
  };

  const handleWaypointLeave = () => {
    setActive(false);
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
    // window.scroll({
    //   top: 0,
    //   left: 0,
    //   behavior: 'smooth',
    // });
  };

  return (
    <>
      <Head>
        <title>Jonathan Christianto &#8212; Software Engineer from Indonesia 🇮🇩</title>
        <meta
          name="description"
          content="Jonathan is a fast learner, someone who are eager to learn new things, on time for everything, and always try to be consistent"
        />
        <meta name="keywords" content="jonathan, christianto, frontend, fe, engineer, em, indonesia, jakarta, surabaya" />
        <link rel="icon" href="/favicon.png" />
      </Head>
      <div>{active ? 'Yes' : 'No'}</div>

      <Header />

      <main className="content-3">
        <Element name="home">
          <SectionHome roles={roles} icons={icons} />
        </Element>

        <Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave}>
          <div>
            <Element name="about">
              <SectionAbout skills={skills} />
            </Element>
            <Element name="experience">
              <SectionExperience experiences={experiences} />
            </Element>
            <Element name="recommendation">
              <SectionRecommendation recommendations={recommendations.reverse()} clients={clients} />
            </Element>
            <Element name="contact">
              <SectionContact />
            </Element>

            <div className="spacer" style={{ height: '96px' }} />

            <footer className="footer">
              <div className="container">
                <span className="copyright">&copy; 2022 Jonathan Christianto.</span>
              </div>
            </footer>
          </div>
        </Waypoint>
      </main>

      {active ? (
        <a id="return-to-top" style={{ display: 'block' }} onClick={scrollToTop}>
          <i className={'fas fa-arrow-up'} />
        </a>
      ) : (
        <></>
      )}
    </>
  );
}
