import moment from 'moment';

/**
 * Format date of the given date according to the given format
 *
 * @param  {?string}      [str=null]              Valid date, eg. 1995-12-17T03:24:00
 * @param  {boolean}      [defaultValue='Invalid date']           Value to be returned if the given date is invalid or null
 * @param  {!string}      [format='MMM YYYY']   Valid moment.js format, https://momentjs.com/docs/#/displaying/format/
 *
 * @returns {string}  Formatted date
 */
function format(str = null, defaultValue = 'Invalid datex', format = 'MMM YYYY') {
  if (str === null) return defaultValue;

  const mom = moment(str);
  return mom.format(format);
}

/**
 * Validate the equality of the two given dates
 *
 * @param  {date}     start               Valid date, eg. 1995-12-17T03:24:00
 * @param  {date}     end                 Valid date, eg. 1995-12-17T03:24:00
 *
 * @returns {boolean}  Adjusted date
 */
function isEqualDate(start, end) {
  return moment(start).isSame(end);
}

function isEqualMonthYear(start, end) {
  const monthStart = moment(start).format('MYYYY');
  const monthEnd = moment(end).format('MYYYY');
  return monthStart === monthEnd;
}

function isEqualYear(start, end) {
  const yearStart = moment(start).format('YYYY');
  const yearEnd = moment(end).format('YYYY');
  return yearStart === yearEnd;
}

/**
 * Display date range based on the given dates, eg. 01 - 31 Jan / 01 - 31 Jan 2021 / 21 Feb - 30 Mar / 21 Feb - 30 Mar 2021
 *
 * @param  {?string}      [start=null]            Valid date, eg. 1995-12-17T03:24:00
 * @param  {?string}      [end=null]              Valid date, eg. 1995-12-17T03:24:00
 * @param  {boolean}      [withYear=true]         Set to true if you would like to have the year displayed
 *
 * @returns {string}  Formatted date
 */
function dateRange(
  start = null,
  end = null,
  startDefaultValue = 'Invalid date',
  endDefaultValue = 'Invalid date',
  formatYear = 'YYYY',
  formatMonth = 'MMM',
  formatDay = 'DD',
) {
  const withYear = formatYear !== null;
  const withMonth = formatMonth !== null;
  const withDay = formatDay !== null;

  const dayLabel = withDay ? ' ' + formatDay ?? 'DD' : '';
  const monthLabel = withMonth ? ' ' + formatMonth ?? 'MMM' : '';
  const yearLabel = withYear ? ' ' + formatYear ?? 'YYYY' : '';
  let format = `${dayLabel + monthLabel + yearLabel}`;
  let startLabel = startDefaultValue;
  let endLabel = endDefaultValue;

  if (start === null && end === null) return `${startLabel} - ${endLabel}`;

  if (isEqualDate(start, end)) {
    return format(start, format, startLabel);
  }

  if (isEqualMonthYear(start, end)) {
    format = `${monthLabel}${yearLabel}`;
    return `${moment(start).format(dayLabel)} - ${moment(end).format(dayLabel)} ${moment(end).format(format)}`;
  }

  if (start !== null) {
    format = dayLabel + monthLabel;
    if (format === '' && withYear) {
      startLabel = moment(start).format(yearLabel);
    } else {
      startLabel = moment(start).format(format);
      if (end === null && withYear) {
        startLabel += moment(start).format(yearLabel);
      }
    }
  }

  if (end != null) {
    format = dayLabel + monthLabel;

    if (format === '' && withYear) {
      endLabel = moment(end).format(yearLabel);
    } else {
      endLabel = moment(end).format(format);

      if (withYear) {
        if (isEqualYear(start, end)) {
          endLabel += moment(end).format(yearLabel);
        } else {
          if (start !== null) startLabel += moment(start).format(yearLabel);
          endLabel += moment(end).format(yearLabel);
        }
      }
    }
  }

  return `${startLabel} - ${endLabel}`;
}

const UtilDate = { format, isEqualDate, isEqualMonthYear, isEqualYear, dateRange };

export default UtilDate;
