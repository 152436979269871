import Image from 'next/image';
import { ProgressBar } from 'react-bootstrap';
import { Waypoint } from 'react-waypoint';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-scroll';

export default function SectionAbout({ skills }) {
  const [active, setActive] = React.useState(false);

  const handleWaypointEnter = () => {
    setTimeout(() => {
      setActive(true);
    }, 500);
  };

  const handleWaypointLeave = () => {
    setActive(false);
  };

  const getDays = () => {
    const date1 = new Date('07/01/2013');
    const date2 = new Date();
    const diffInTime = date2.getTime() - date1.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    return Math.round(diffInDays * 0.75);
  };

  return (
    <section id="about">
      <div className="container">
        <ScrollAnimation animateIn="fadeInUp" delay={300} animateOnce={true}>
          <h2 className="section-title">About Me</h2>
        </ScrollAnimation>

        <div className="spacer" style={{ height: '60px' }} />

        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <Image src="/images/avatar.png" alt="Jonathan Christianto" width={150} height={150} layout="fixed" />
            </div>
            <div className="spacer d-md-none d-lg-none" style={{ height: '30px' }} />
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-dark shadow-light padding-30">
              <div className="row">
                <div className="col-md-6">
                  {/* <!-- about text --> */}
                  <p>I am Jonathan, web developer from Jakarta, Indonesia. I have rich experience in web development and web system design.</p>

                  <div className="fact-item mt-3">
                    <span className="icon icon-cup" />
                    <div className="details">
                      <h3 className="mb-0 mt-0 number">
                        <em className="count">{getDays()}</em>
                      </h3>
                      <p className="mb-0">Cup of coffee</p>
                    </div>
                  </div>

                  <div className="mt-3">
                    <Link activeClass="active" to="contact" spy={true} smooth={true} className="btn btn-default" href={'#contact'}>
                      Ask me anything!
                    </Link>
                  </div>
                  <div className="spacer d-md-none d-lg-none" style={{ height: '30px' }} />
                </div>
                <div className="col-md-6">
                  <Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave} />
                  {skills.map(skill => (
                    <div className="skill-item" key={'skill-' + skill.id}>
                      <style>
                        {`
                          .progress-bar.bg-` +
                          skill.id +
                          `{
                            background-color: ` +
                          skill.color +
                          `;
                          }
                        `}
                      </style>
                      <div className="skill-info clearfix">
                        <h4 className="float-left mb-3 mt-0">{skill.label}</h4>
                        <span className="float-right">{active ? skill.percentage : 0}%</span>
                      </div>
                      <ProgressBar variant={skill.id} now={active ? skill.percentage : 0} />
                      {/* <div className="progress">
                        <div
                          className="progress-bar data-background"
                          role="progressbar"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          aria-valuenow={skill.percentage}
                          data-color="#FFD15C"
                        ></div>
                      </div> */}
                      <div className="spacer" style={{ height: '20px' }} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="spacer" style={{ height: '70px' }} />

        <div className="row">
          <div className="col-md-3 col-sm-6">
            <div className="fact-item">
              <span className="icon icon-fire"></span>
              <div className="details">
                <h3 className="mb-0 mt-0 number">
                  <em className="count">198</em>
                </h3>
                <p className="mb-0">Projects completed</p>
              </div>
            </div>
            <div className="spacer d-md-none d-lg-none" style={{ height: '30px' }} />
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="fact-item">
              <span className="icon icon-cup" />
              <div className="details">
                <h3 className="mb-0 mt-0 number">
                  <em className="count">5670</em>
                </h3>
                <p className="mb-0">Cup of coffee</p>
              </div>
            </div>
            <div className="spacer d-md-none d-lg-none" style={{ height: '30px' }} />
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="fact-item">
              <span className="icon icon-people"></span>
              <div className="details">
                <h3 className="mb-0 mt-0 number">
                  <em className="count">427</em>
                </h3>
                <p className="mb-0">Satisfied clients</p>
              </div>
            </div>
            <div className="spacer d-md-none d-lg-none" style={{ height: '30px' }} />
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="fact-item">
              <span className="icon icon-badge"></span>
              <div className="details">
                <h3 className="mb-0 mt-0 number">
                  <em className="count">35</em>
                </h3>
                <p className="mb-0">Nominees winner</p>
              </div>
            </div>
          </div>
        </div>
         */}
      </div>
    </section>
  );
}
