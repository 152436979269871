import Slider from 'react-slick';
import ScrollAnimation from 'react-animate-on-scroll';

export default function SectionRecommendation({ recommendations, clients }) {
  const settings = {
    dots: true,
    infinite: true,
    // speed: 500,
    // slidesToShow: 1,
    // slidesToScroll: 1,

    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <section id="testimonials">
      <div className="container">
        <ScrollAnimation animateIn="fadeInUp" delay={300} animateOnce={true}>
          <h2 className="section-title">Recommendations</h2>
        </ScrollAnimation>

        <div className="spacer" style={{ height: '60px' }} />

        <div className="testimonials-wrapper">
          <Slider {...settings}>
            {recommendations.map((recommendation, index) => (
              <div className="testimonial-item text-center mx-auto" key={'block-recommendations-' + index}>
                {/* <div className="thumb mb-3 mx-auto">
                  <img src={recommendation.avatar_url} alt="customer-name" />
                </div> */}
                <h4 className="mt-3 mb-0">
                  <a href={recommendation.url} target="_blank" rel="noreferrer noopener">
                    {recommendation.name}
                  </a>
                </h4>
                <span className="subtitle">{recommendation.role}</span>
                <div className="bg-dark padding-30 shadow-light rounded triangle-top position-relative mt-4">
                  <p className="mb-0">{recommendation.message}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        <div className="row">
          {clients.map((client, index) => (
            <div className="col-md-4 col-12" key={'block-clients-' + index}>
              <ScrollAnimation animateIn="fadeIn" delay={500 * (index + 0.5)} duration={2} animateOnce={true}>
                <div className="client-item">
                  <div className="inner">
                    <a href={client.url} target="_blank" rel="noreferrer noopener">
                      <img
                        src={client.logo_url}
                        srcSet={client.logo_url + ' 1x, ' + client.logo_url_2 + ' 2x'}
                        alt={client.name}
                        style={{ height: '35px' }}
                        title={client.name}
                      />
                    </a>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
