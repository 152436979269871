import ScrollAnimation from 'react-animate-on-scroll';
import React from 'react';
import { useForm } from 'react-hook-form';

const actionUrl = process.env.NEXT_PUBLIC_FORM_URL || null;

export default function SectionContact() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [formSent, setFormSent] = React.useState(null);

  const responseMessage = () => {
    if (actionUrl === null) {
      return (
        <div className="messages alert alert-info p-4">
          <strong>Thanks for dropping by</strong>
          <br />
          Due to the number of messages that have been received beyond our expectations, you will not be able to send messages at this time. Please
          try again later, or contact us on social media.
        </div>
      );
    }

    if (formSent) {
      return (
        <div className="messages alert alert-success">
          <strong>Thank you 👍</strong>
          <br />
          Your message has been sent, we will get back to you soon 🙏
        </div>
      );
    }

    if (formSent === false) {
      return (
        <div className="messages alert alert-warning">
          <strong>Sorry</strong>
          <br />
          We failed to send your message, please try again 🙈
        </div>
      );
    }

    return <></>;
  };

  const submitFormSpark = async (data, evt) => {
    try {
      evt.preventDefault();
      setFormSent(null);

      await fetch(actionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(data),
      });

      setFormSent(true);
      document.getElementById('contact-form').reset();
    } catch (error) {
      setFormSent(false);
      console.info(error);
    }
  };

  return (
    <section id="contact">
      <div className="container">
        <ScrollAnimation animateIn="fadeInUp" delay={300} animateOnce={true}>
          <h2 className="section-title">Contact</h2>
        </ScrollAnimation>

        <div className="spacer" style={{ height: '60px' }} />

        <div className="row">
          <div className="col-md-4">
            <div className="contact-info">
              <ScrollAnimation animateIn="fadeInUp" delay={400} animateOnce={true}>
                <h3>Let&rsquo;s talk about everything</h3>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" delay={500} animateOnce={true}>
                <p>If you&rsquo;re interested in working together or just want to say hello 👋</p>
              </ScrollAnimation>
            </div>
          </div>

          <div className="col-md-8">
            {/* <!-- Contact Form --> */}

            <form id="contact-form" className="contact-form mt-6" onSubmit={handleSubmit(submitFormSpark)}>
              {responseMessage()}

              {actionUrl !== null ? (
                <>
                  <div className="row">
                    <div className="column col-md-6">
                      {/* <!-- Name input --> */}
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="full_name"
                          id="full_name"
                          placeholder="Your name"
                          {...register('full_name', {
                            required: { value: true, message: 'Your name is required' },
                            minLength: { value: 2, message: 'Your name can not be less than 3 and more than 50 characters' },
                            maxLength: { value: 50, message: 'Your name can not be less than 3 and more than 50 characters' },
                          })}
                        />
                        {errors.full_name && <div className="help-block with-errors mt-2">{errors.full_name.message}</div>}
                      </div>
                    </div>

                    <div className="column col-md-6">
                      {/* <!-- Email input --> */}
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          id="email_address"
                          name="email_address"
                          placeholder="Email address"
                          {...register('email_address', {
                            required: { value: true, message: 'Email address is required' },
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: 'Invalid email address',
                            },
                          })}
                        />
                        {errors.email_address && <div className="help-block with-errors mt-2">{errors.email_address.message}</div>}
                      </div>
                    </div>

                    <div className="column col-md-12">
                      {/* <!-- Email input --> */}
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="subject"
                          name="subject"
                          placeholder="Subject"
                          {...register('subject', {
                            required: { value: true, message: 'Subject is required' },
                            minLength: { value: 3, message: 'Subject can not be less than 3 and more than 200 characters' },
                            maxLength: { value: 200, message: 'Subject can not be less than 3 and more than 200 characters' },
                          })}
                        />
                        {errors.subject && <div className="help-block with-errors mt-2">{errors.subject.message}</div>}
                      </div>
                    </div>

                    <div className="column col-md-12">
                      {/* <!-- Message textarea --> */}
                      <div className="form-group">
                        <textarea
                          name="message"
                          id="message"
                          className="form-control"
                          rows="5"
                          placeholder="Message"
                          {...register('message', {
                            required: { value: true, message: 'Message is required' },
                            minLength: { value: 3, message: 'Message can not be less than 3 and more than 500 characters' },
                            maxLength: { value: 500, message: 'Message not be less than 3 and more than 500 characters' },
                          })}
                        />
                        {errors.message && <div className="help-block with-errors mt-2">{errors.message.message}</div>}
                      </div>
                    </div>
                  </div>

                  <button type="submit" name="submit" id="submit" value="Submit" className="btn btn-default">
                    Send Message
                  </button>
                </>
              ) : (
                <></>
              )}
            </form>
            {/* <!-- Contact Form end --> */}
          </div>
        </div>
      </div>
    </section>
  );
}
